/* Floating button */
.floating-button {
    position: fixed;
    bottom: 50px;
    right: 40px;
    z-index: 99999;
}

.floating-btn {
    width: 60px;
    height: 60px;
    font-size: 24px;
    padding: 12px;
    border-radius: 50%;
    background: linear-gradient(to right, #1775A1, #0D4A6A);
    border: 2px solid #eff3f8;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 99999;
}

.floating-btn i {
    color: white;
}

.floating-btn:hover {
    transform: scale(1.1);
    border: 2px solid #eff3f8;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

/* Popup box */

.popup {
    position: fixed;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    /* background: #A8C6DE; */
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    z-index: 99999;
    width: 200px;
    margin-bottom: 30px;
    border: 3px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: border-box;
    background-image: linear-gradient(#EFF3F8, #EFF3F8), 
                      linear-gradient(135deg, #1775A1, #D63384, #8E44AD);
}

.popup-header {
    text-align: center;
}

/* Agent Image */
.agent {
    text-align: center;
}

.agent-image {
    width: 50%;
    border-radius: 50%;
    border: 5px solid #2089BA;
    box-shadow: 0 8px 15px rgba(38, 125, 170, 0.4);
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Language dropdown */
.language-select {
    width: 100% !important;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: #565D77;
    background-color: #fff;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    appearance: none;
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23565D77' width='20px' height='20px'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 24px;
}

.language-select:focus {
    outline: 0;
    border-color: #2089BA;
    box-shadow: 0 0 6px rgba(32, 137, 186, 0.7);
}

/* Speak button */
.speak-btn {
    width: 100%;
    margin-top: 10px;
    padding: 8px 10px;
    background: linear-gradient(to bottom right, #2983AD 0%, #2983AD 40%, #34E9C5 100%);
    border-radius: 12px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease-in-out;
}

.speak-btn:hover {
    background: #1775A1;
}

.speak-btn i {
    margin-right: 8px;
}

/* Listening animation */
.listening-animation {
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}

.dot {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* End Conversation Button */
.end-conversation-btn {
    width: 100%;
    border-radius: 10px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.end-conversation-btn:hover {
    opacity: 0.8;
    transform: scale(1.05);
}

/* Powered by Vozzo */
.powered-by {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    margin-top: 10px;
}

.vozzo-icon {
    width: 20px;
    height: 20px;
}

.powered-by p {
    font-size: 10px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: gray;
    margin: 0;
}

/* ============================================================================ */


/* ---------- Microphone Icon (Static) ---------- */
.mic-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: #ffffff;
    /* white for contrast */
    z-index: 2;
    /* No animation on the icon */
}

.voice-motion {
    margin: 10px 0;
    font-weight: bold;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

/* Container for animated button */
.animated-button-container .container {
    position: relative;
    width: 160px;
    height: 160px;
    /* margin-top: 20px; */
}

/* Rotating rings */
.circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
    opacity: 0.8;
    transform-origin: center;
    transform: translate(-50%, -50%);
    z-index: 5;
}

.circle.circle1 {
    background: radial-gradient(circle, rgba(74, 144, 226, 0.7) 0%, rgba(123, 31, 162, 0.7) 100%);
    animation: rotate3d 4s linear infinite;
}

.circle.circle2 {
    background: radial-gradient(circle, rgba(255, 87, 34, 0.7) 0%, rgba(255, 193, 7, 0.7) 100%);
    animation: rotate3d 5s linear infinite reverse;
}

.circle.circle3 {
    background: radial-gradient(circle, rgba(0, 200, 83, 0.7) 0%, rgba(0, 150, 136, 0.7) 100%);
    animation: rotate3d 6s linear infinite;
}

.circle.circle4 {
    background: radial-gradient(circle, rgba(233, 30, 99, 0.7) 0%, rgba(156, 39, 176, 0.7) 100%);
    animation: rotate3d 7s linear infinite reverse;
}

@keyframes rotate3d {
    from {
        transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}

/* Fluid wave animation */
.btn-wave {
    position: absolute;
    width: 65px;
    height: 65px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    transform: translate(-50%, -50%);
    animation: waveAnimation 3s ease-in-out infinite;
    z-index: 4;
}

.btn-wave:nth-of-type(1) {
    animation-delay: 0s;
}

.btn-wave:nth-of-type(2) {
    animation-delay: 1s;
}

.btn-wave:nth-of-type(3) {
    animation-delay: 2s;
}

@keyframes waveAnimation {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0.9;
    }

    50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0;
    }
}

/* Main button with mic icon */
.button {
    position: absolute;
    font-size: 25px;
    color: black;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(0, 255, 255, 0.5), rgba(255, 0, 255, 0.5));
    border: 2px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 20;
    cursor: pointer;
}

.mic-icon {
    width: 32px;
    height: 32px;
    pointer-events: none;
}

/* Additional styles for your popup and other elements remain unchanged */
/* Waveform container */
.waveform-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

/* Each bar of the waveform */
.waveform-container .bar {
    width: 8px;
    height: 20px;
    background-color: #4caf50;
    margin: 0 2px;
    animation: btn-wave 1.2s infinite ease-in-out;
}

/* Delay for each bar for a staggered effect */
.waveform-container .bar:nth-child(1) {
    animation-delay: -1.2s;
}

.waveform-container .bar:nth-child(2) {
    animation-delay: -1.0s;
}

.waveform-container .bar:nth-child(3) {
    animation-delay: -0.8s;
}

.waveform-container .bar:nth-child(4) {
    animation-delay: -0.6s;
}

.waveform-container .bar:nth-child(5) {
    animation-delay: -0.4s;
}

@keyframes btn-wave {

    0%,
    40%,
    100% {
        transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
    }
}


/* Wave mic container around the microphone */
.wave-mic-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Each wave segment (left and right) */
.wave-segment {
    display: flex;
    align-items: center;
}

.wave-segment.left .bar {
    margin-right: 4px;
}

.wave-segment.right .bar {
    margin-left: 4px;
}

/* Mic icon in the middle */
.wave-mic-container .mic-icon img {
    width: 40px;
    height: 40px;
    /* example: invert color if needed for dark backgrounds */
    filter: invert(100%);
    margin: 0 16px;
}

/* Bars for the wave animation */
.bar {
    width: 3px;
    /* thickness of the bar */
    height: 15px;
    /* default/starting height */
    background-color: #9f50ff;
    /* wave color */
    animation: wave 1.2s infinite ease-in-out;
}

/* Staggered animation delays for each bar to create wave effect */
.bar1 {
    animation-delay: 0s;
}

.bar2 {
    animation-delay: -0.2s;
}

.bar3 {
    animation-delay: -0.4s;
}

.bar4 {
    animation-delay: -0.6s;
}

.bar5 {
    animation-delay: -0.8s;
}

/* Keyframes for the wave animation: scale up/down vertically */
@keyframes btn-wave {

    0%,
    40%,
    100% {
        transform: scaleY(0.3);
    }

    20% {
        transform: scaleY(1);
    }
}

@keyframes btn-wave {
    0% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(2);
    }

    100% {
        transform: scaleY(1);
    }
}


.btn-wave {
    position: absolute;
    width: 65px;
    height: 65px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    /* border: 2px solid rgba(255, 255, 255, 0.3); */
    /* border: 2px solid rgba(255, 0, 255, 0.3); */
    border: 2px solid rgba(142, 68, 173, 0.3);
    transform: translate(-50%, -50%);
    animation: waveAnimation 3s ease-in-out infinite;
    z-index: 4;
}

.btn-wave:nth-of-type(1) {
    animation-delay: 0s;
}

.btn-wave:nth-of-type(2) {
    animation-delay: 1s;
}

.btn-wave:nth-of-type(3) {
    animation-delay: 2s;
}

/* .wave-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  } */

.listening-animation {
    margin: 10px 0;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.dot {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* WaveMicComponent.css */
.wave-mic-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wave-mic-inner-container {
    position: relative;
    width: 50px;
    height: 50px;
    z-index: 1;
}

.wave-mic-circular-wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.1s linear, background 0.1s linear;
    z-index: 10;
}

.wave-mic-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(0, 255, 255, 0.5), rgba(255, 0, 255, 0.5));
    border: 2px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 20;
    cursor: pointer;
}

.wave-mic-icon {
    width: 35px;
    height: 35px;
}

.end-conversation-btn {
    padding: 8px 10px;
    width: 100%;
    border-radius: 12px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    transition: all 0.3s ease-in-out;
}

.end-conversation-btn:hover {
    opacity: 0.8;
    transform: scale(1.05);
}

/* Centered Animated Button at the Bottom */
.animated-button-container {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 9999;
}

/* Centered Popup above the Button */

.wave-mic-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding: 20px 0 30px 0;
}

.mic-cont {
    position: relative;
    width: 50px;
    height: 50px;
    z-index: 1;
}

.circular-wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: transform 0.1s linear, background 0.1s linear;
    z-index: 10;
}

.mic-button {
    position: absolute;
    padding: 30px;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(0, 255, 255, 0.5), rgba(255, 0, 255, 0.5));
    border: 2px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 20;
    cursor: pointer;
}