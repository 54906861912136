/* Section Padding */
#about {
  padding: 50px;
}

/* Navbar Styles */
#menu {
  border-radius: 40px;
  top: 20px;
  left: 30px;
  right: 30px;
  box-sizing: border-box;
  overflow: hidden;
}

.navbar-header img {
  transform: scale(1.2);
}

/* #menu .btn-custom {
  margin-left: 20px;
} */

/* Header section */

.intro-image {
  padding: 90px 50px;
}

.intro-image img {
  width: 115%;
}

/* pop-up section */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease;
}

.popup-content {
  background: #eff3f8;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 20px 40px;
  border-radius: 20px;
  text-align: center;
  max-width: 350px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#contact {
  padding-top: 140px;
}

/* Intro Section Styling */

.intro-section {
  margin-top: 30px;
  background-color: #fff;
  text-align: center;
  padding: 50px;
}

.intro-section-container {
  padding: 100px 220px;
  border-radius: 20px;
  background-color: #EFF3F8;
  margin: 0 auto;
  line-height: 1.2;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  /* max-width: 1200px; */
}

/* .intro-section-subtitle {
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
}

.intro-section-subtitle span {
  display: block;
} */

.intro-section-subtitle {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.intro-section-subtitle span {
  color: #ccc;
  transition: color 0.4s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(5px);
}

.scrolled span {
  color: #0D4A6A;
  transform: translateY(0);
}

.scrolled span:nth-child(1) {
  transition-delay: 0.1s;
}

.scrolled span:nth-child(2) {
  transition-delay: 0.3s;
}

.scrolled span:nth-child(3) {
  transition-delay: 0.5s;
}

.scrolled span:nth-child(4) {
  transition-delay: 0.7s;
}

.scrolled span:nth-child(5) {
  transition-delay: 0.9s;
}

.scrolled span:nth-child(6) {
  transition-delay: 1.1s;
}

.scrolled span:nth-child(7) {
  transition-delay: 1.3s;
}

.scrolled span:nth-child(8) {
  transition-delay: 1.5s;
}

.scrolled span:nth-child(9) {
  transition-delay: 1.7s;
}

.scrolled span:nth-child(10) {
  transition-delay: 1.9s;
}

.scrolled span:nth-child(11) {
  transition-delay: 2.1s;
}

.scrolled span:nth-child(12) {
  transition-delay: 2.3s;
}

.intro-section-description {
  color: #565D77;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 15px;
}

.cta-btn-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
}

#intro-section .enterprise-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.demo-btn,
.enterprise-btn {
  width: 180px;
}

/******************* ABOUT PAGE***************** */

/* About Page Styling */

/* General container styling */

#about-page {
  font-family: "Montserrat", sans-serif;
}

.company-story {
  width: 100%;
  height: 100%;
  padding-top: 150px;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  background: linear-gradient(to bottom right, #EFF3F8, #CBDCEB, #A8C6DE, #86B0D1, #6599C4);
  position: relative;
}

.company-story h2 {
  /* background: linear-gradient(to right, #34E9C5, #1775A1, #0D4A6A); */
  background: linear-gradient(to right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#header .wave-container {
  top: 516px;
  width: 100%;
  height: 150px;
}

.wave-container {
  position: absolute;
  top: 360px;
  left: 0;
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.waves {
  position: relative;
  width: 200%;
  height: 100%;
  animation: move-waves 5s ease-in-out infinite alternate;
}

@keyframes move-waves {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.company-story-p {
  padding-top: 30px;
  padding-bottom: 45px;
  padding-left: 60px;
  padding-right: 60px;
}

#about-page .section-title {
  margin: 0;
}

#mission-section {
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 60px;
}

#mission-section .container {
  width: 100%;
  height: 100%;
}

#about-page .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mission-vision-values {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
}

.mission-item {
  /* background: linear-gradient(to bottom, #A8C6DE, #A1D1E8, #B8C9E7, #E0D8F5); */
  background-color: #eff3f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 30%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 1rem;
  padding: 2rem;
  max-width: 375px;
}

.mission-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #eff3f8;
}

/* Section Headings */

.mission-vision-values h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

/* Paragraphs */
.company-story p {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #565d77;
  margin-bottom: 1.5rem;
}

.mission-vision-values p {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
}

.mission-vision-values span {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 0.6;
  color: #555;
  margin-bottom: 1.5rem;
}

.team p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
}

/* Team Section */

#team-section {
  background-color: #eff3f8;
}

#team-section .container {
  width: 100%;
  height: 100%;
}

#team-section .row {
  padding-top: 50px;
}

.team {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

#about-page .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#about-page .team .team-member {
  margin: 1rem;
  padding: 2rem;
  max-width: 375px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: linear-gradient(to bottom, #eff3f8, #cbdceb);
  /* background: linear-gradient(to top, #EFF3F8, #CBDCEB, #A1D1E8); */
  /* background: linear-gradient(to bottom, #EFF3F8, #D1DFF5, #A4C6E7, #7A9FCA); */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

#about-page .team .team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

#about-page .team .team-member img {
  width: 100px;
  height: 100px;
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

#about-page .team .team-member h3 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 1rem;
}

#about-page .team .team-member p {
  font-size: 1.5rem;
  color: #555;
  margin: 0;
}

/* *************************** */

/* *********** AI Services Styling *******************/

#ai-services {
  padding: 100px 0 50px;
}

#ai-services .section-title {
  margin-bottom: 50px;
  text-align: center;
}

.tabs-container {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
  overflow: hidden;
}

.tab {
  max-width: 150px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.tab:hover {
  color: #299DD3;
}

.tab.active {
  color: #299DD3;
  border-bottom: 2px solid #299DD3;
}

.ai-services-content-section {
  /* text-align: center; */
  margin-top: 20px;
  max-width: 1200px;
  margin: auto;
  padding: 0 50px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  /* #ai-services {
    padding: 60px 0 30px;
  } */

  .tabs-container {
    gap: 30px;
  }

  .tab {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  /* #ai-services {
    padding: 50px 0 25px;
  } */

  .ai-services-content-section {
    padding: 0 10px;
  }

  .tabs-container {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    padding: 10px;
    gap: 10px;
    scroll-snap-type: x mandatory;
  }

  .tab {
    flex: 0 0 auto;
    scroll-snap-align: center;
  }

  .tabs-container::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 480px) {
  /* #ai-services {
    padding: 40px 0 20px;
  } */

  .ai-services-content-section {
    padding: 0;
  }

  .tab {
    font-size: 13px;
    padding: 8px;
    max-width: 250px;
  }
}

/* *************************** */

/* *********** VozzoAgent Styling *******************/

.vozzo-agent-header {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.vozzo-agent-header h1 {
  font-size: 52px;
  line-height: 66px;
  font-weight: 700;
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* margin: 50px auto; */
  margin-top: 50px;
  padding: 0 50px;
}

.vozzo-agent-header p {
  width: 600px;
  margin: 0 auto;
}

.vozzo-agent-header img {
  /* width: 1000px; */
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.vozzo-agent-header button,
.xcent-header-left button,
.xcent-security button,
.xcent-cta button {
  background: linear-gradient(90deg, #299DD3, #007bbf);
  color: white;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.vozzo-agent-header button {
  margin: 30px 0 50px 0;
}

.xcent-header-left button {
  margin: 0 0 20px 0;
}

.vozzo-agent-header button:hover,
.xcent-header-left button:hover {
  background: #1775A1;
  transform: scale(1.05);
}

.vozzo-agent-tabs {
  /* display: flex;
  justify-content: center; 
  gap: 20px;
  margin: 50px auto;
  padding: 0 20px;
  overflow: hidden; */

  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px auto;
  padding: 10px 0;
  overflow: hidden;
}

.agent-tab,
.xcent-tab {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #777;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}

.agent-tab:hover,
.xcent-tab:hover {
  background: #EFF3F8;
  border: 1px solid #299DD3;
  color: #299DD3;
}

.agent-tab.active,
.xcent-tab.active {
  background: #299DD3;
  color: white;
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.2);
}

.language-voice-left ul,
.ai-models ul,
.custom-integrations ul,
.deployment ul,
.ai-analytics ul {
  list-style: none;
  padding: 0;
}

.language-voice-left ul li,
.ai-models ul li,
.custom-integrations ul li,
.deployment ul li,
.ai-analytics ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.language-voice-left ul li::before,
.ai-models ul li::before,
.custom-integrations ul li::before,
.deployment ul li::before,
.ai-analytics ul li::before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.6rem;
  color: #299DD3;
  font-weight: bold;
}

.usecases-tab-content ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.usecases-tab-content ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.usecases-tab-content ul li::before {
  content: "✔";
  position: absolute;
  left: 0;
  top: 2px;
  color: #299DD3;
  font-size: 1.6rem;
  font-weight: bold;
}

.vozzo-agent-usecases {
  /* display: flex;
  align-items: flex-start;
  flex-wrap: wrap; */
  /* gap: 20px; */
  margin: 50px auto;
}

.agent-usecases-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 50px auto;
  padding: 10px;
  overflow: hidden;
}

/* .usecases-tab {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  text-align: left;
} */

.agent-usecases-tabs {
  /* display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 50px auto;
  padding: 10px 0;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; */

  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.usecases-tab {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #777;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  flex: 0 0 auto;
  scroll-snap-align: start;
}

/* Hide scrollbar but allow scrolling */
.agent-usecases-tabs::-webkit-scrollbar {
  display: none;
}

.usecases-tab:hover {
  color: #299DD3;
  border-color: #299DD3;
}

/* Active tab */
.usecases-tab.active {
  background-color: #299DD3;
  color: white;
  border-color: #299DD3;
  font-weight: 600;
}

/* Right-side content */
.usecases-tab-content {
  flex: 1;
  /* width: 500px; */
  text-align: left;
  padding: 50px 80px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* RESPONSIVE DESIGN */
@media (max-width: 1024px) {
  .vozzo-agent-header h1 {
    font-size: 42px;
    line-height: 54px;
    padding: 0 30px;
    text-align: center;
  }

  .vozzo-agent-header p {
    width: 80%;
    text-align: center;
  }

  /* .language-voice-left h3 {
    text-align: center;
  } */
}

@media (max-width: 768px) {
  .vozzo-agent-header {
    padding: 0 20px;
  }

  .vozzo-agent-header h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .vozzo-agent-header p {
    width: 90%;
  }

  .vozzo-agent-tabs {
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    gap: 10px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .agent-tab {
    flex: 0 0 auto;
    scroll-snap-align: start;
  }

  .vozzo-agent-tabs::before {
    content: "";
    flex: 0 0 10px;
  }

  .vozzo-agent-tabs::-webkit-scrollbar {
    display: none;
  }

  /* .agent-usecases-tabs {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-left: 0;
    margin-left: 0;
  } */

  .usecases-tab {
    flex: 0 0 auto;
    scroll-snap-align: start;
    white-space: nowrap;
  }

  .agent-usecases-tabs::before {
    content: "";
    display: none;
  }

  .agent-usecases-tabs::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 480px) {
  .vozzo-agent-header {
    padding: 0 10px;
  }

  .vozzo-agent-header h1 {
    font-size: 28px;
    line-height: 38px;
    padding: 0 10px;
  }

  .vozzo-agent-header p {
    width: 100%;
  }

  .vozzo-agent-features {
    padding: 0 10px;
  }

  .vozzo-agent-usecases {
    padding: 0 10px;
  }

  .usecases-tab-content {
    padding: 20px 30px;
  }
}

/* *************************** */

/* *********** VozzoXcent Styling *******************/

.xcent-header,
.language-voice,
.ai-models,
.custom-integrations,
.deployment,
.ai-analytics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  max-width: 1200px;
  margin: 50px auto;
  text-align: left;
  /* padding-bottom: 50px; */
}

.xcent-header-left,
.language-voice-left,
.ai-models-left,
.custom-integrations-left,
.deployment-left,
.ai-analytics-left {
  /* flex: 1; */
  max-width: 500px;
}

.xcent-header-left h1 {
  font-size: 42px;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 10px 0;
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#VozzoXcent h2,
#VozzoAgent h2 {
  margin: 50px auto;
}

#VozzoXcent h3,
#VozzoAgent h3 {
  font-size: 4rem;
  color: #0D4A6A;
  font-weight: bold;
  margin: 0 0 20px 0;
}

#VozzoXcent h4,
#VozzoAgent h4 {
  font-size: 3rem;
  color: #0D4A6A;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
}

#VozzoXcent h5,
#VozzoAgent h5 {
  font-size: 2rem;
  color: #0D4A6A;
  font-weight: 600;
  margin-bottom: 20px;
}

#VozzoXcent p,
#VozzoAgent p {
  line-height: 28px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.xcent-header-left ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.xcent-header-left ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.xcent-header-left ul li::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #299DD3;
  font-size: 1.6rem;
  font-weight: bold;
}

.xcent-header-right,
.language-voice-right .ai-models-right,
.custom-integrations-right,
.deployment-right,
.ai-analytics-right {
  flex: 1;
  text-align: right;
}

.xcent-header-right img,
.language-voice-right img,
.ai-models-right img,
.custom-integrations-right img,
.deployment-right img,
.ai-analytics-right img {
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
}

.xcent-integration {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 50px 0;
  max-width: 1200px;
  margin: auto;
  text-align: left;
}

.xcent-integration-left {
  flex: 1;
  max-width: 600px;
}

.xcent-integration-right {
  flex: 1;
  text-align: right;
}

.xcent-integration-right img {
  width: 100%;
  max-width: 600px;
  border-radius: 20px;
}

/* Accent Conversion Section */
.xcent-conversion {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.xcent-conversion h3 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.xcent-tab-container {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 50px auto; */

  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 50px auto;
  padding: 10px;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.indian-accent,
.uk-accent {
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.indian-accent-header,
.uk-accent-header {
  margin-bottom: 40px;
}

.indian-accent-content,
.uk-accent-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 0 20px;
  flex-wrap: wrap;
}

.indian-accent-left,
.uk-accent-left,
.indian-accent-right,
.uk-accent-right {
  flex: 1;
}

.indian-accent img,
.uk-accent img {
  width: 100%;
  max-width: 550px;
  border-radius: 20px;
}

/* Audio Demo Section */
.xcent-audio-demo {
  text-align: center;
  padding: 30px 60px;
  background: #EFF3F8;
  border-radius: 20px;
  max-width: 500px;
  margin: 50px auto 0;
}

.audio-player {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
}

.play-btn {
  background: #299DD3;
  color: white;
  border: none;
  width: 50px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.play-btn:hover {
  background: #1775A1;
}

.progress-bar {
  width: 60%;
  height: 5px;
  border-radius: 5px;
  background: #ddd;
  /* appearance: none; */
}

/* Toggle Button */
.vozzo-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.vozzo-toggle span {
  font-size: 16px;
  color: #666;
}

.vozzo-toggle .active {
  font-weight: bold;
  color: #299DD3;
}

/* Toggle Switch */
.switch {
  position: relative;
  width: 50px;
  height: 25px;
  top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.switch input:checked+.slider {
  background-color: #299DD3;
}

.switch input:checked+.slider::before {
  transform: translateX(24px);
}

/* Agent Selection */
.agent-selection {
  margin-top: 20px;
}

.agent-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

/* Radio Buttons */
.radio-container {
  width: 100px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 auto;
}

.radio-container input {
  accent-color: #299DD3;
  margin: 0;
}

/* Security Section */
.xcent-security {
  text-align: center;
  padding: 50px 50px 0 50px;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* max-width: 900px; */
  margin: 50px auto;
}

/* Security Content Layout */
.security-content {
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 20px;
  padding: 50px 0;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}

.security-content h4 {
  background-color: #CBDCEB;
  padding: 8px 20px;
  border-radius: 10px;
}

.security-left,
.security-right {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #299DD3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.security-icon {
  margin-right: 10px;
  color: #299DD3;
  font-size: 3rem;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

/* List Items */
.security-left ul,
.security-right ul {
  list-style: none;
  padding: 0;
}

.security-left li,
.security-right li {
  /* padding: 8px 0; */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.security-left li::before,
.security-right li::before {
  content: "✔";
  color: #299DD3;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.xcent-security button {
  margin: 50px auto;
}

.xcent-cta button {
  margin: 20px auto 50px;
}

.xcent-cta {
  max-width: 700px;
  margin: 100px auto 0;
  text-align: center;
}

/* RESPONSIVE DESIGN */
@media (max-width: 1024px) {

  .xcent-header-left {
    text-align: center;
  }

  .xcent-header-right {
    text-align: center;
  }

  #VozzoXcent h1,
  #VozzoAgent h1 {
    text-align: center;
  }

  .xcent-header,
  .language-voice,
  .ai-models,
  .custom-integrations,
  .deployment,
  .ai-analytics {
    flex-direction: column;
  }

  .xcent-header-left,
  .language-voice-left,
  .ai-models-left,
  .custom-integrations-left,
  .deployment-left,
  .ai-analytics-left {
    max-width: 90%;
  }

  .xcent-header-right img {
    max-width: 80%;
  }
}

@media (max-width: 768px) {

  .xcent-header-left h1 {
    font-size: 36px;
  }

  #VozzoXcent h3,
  #VozzoAgent h3 {
    font-size: 3rem;
  }

  #VozzoXcent h4,
  #VozzoAgent h4 {
    font-size: 2.5rem;
  }

  .xcent-header-right img {
    max-width: 100%;
  }

  .security-content {
    flex-direction: column;
  }

  .xcent-integration {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .xcent-cta {
    padding: 0 20px;
  }

  .indian-accent-content,
  .uk-accent-content {
    flex-direction: column;
    text-align: center;
  }

  .xcent-tab-container {
    display: flex;
    /* justify-content: flex-start;  */
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-left: 0;
    margin-left: 0;
  }

  .xcent-tab-container::-webkit-scrollbar {
    display: none;
  }

  .agent-options {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}

@media (max-width: 480px) {

  .language-voice-right,
  .ai-models-right,
  .custom-integrations-right,
  .deployment-right,
  .ai-analytics-right {
    padding: 0 10px;
  }

  .xcent-tab-container {
    justify-content: flex-start;
  }

  .xcent-header-right {
    padding: 0 10px;
  }

  .xcent-header-left h1 {
    font-size: 28px;
  }

  .xcent-header-right img {
    max-width: 100%;
  }

  .xcent-security {
    padding: 40px 10px 0 10px;
  }

  .indian-accent-content,
  .uk-accent-content {
    padding: 0;
  }

  .xcent-audio-demo {
    padding: 15px 20px;
  }
}

/* *************************** */

/* *********** FynBuddy Page Styling *******************/

#fynbuddy {
  font-family: "Montserrat", sans-serif;
  /* padding-top: 100px; */
  background: linear-gradient(to bottom, #eff3f8, #cbdceb);
}

#fynbuddy .intro-text {
  padding-top: 200px;
  text-align: left;
}

#fynbuddy .intro-image {
  padding-top: 85px;
  padding-bottom: 60px;
}

#fynbuddy .intro-image img {
  width: 100%;
}

#fynbuddy .wave-container {
  top: 500px;
}

.fynbuddy-container {
  background-color: #EFF3F8;
}

.fynbuddy-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 100px 0;
}

#fynbuddy h2 {
  width: 80rem;
  margin: 0 auto;
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
}

#fynbuddy h3 {
  width: 80rem;
  margin: 0 auto;
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
}

#fynbuddy h4 {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to bottom right, #299DD3, #1775A1, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fynbuddy-section-title p {
  width: 80rem;
  margin: 0 auto;
  margin-top: 20px;
  color: #565D77;
}

.fynbuddy-section-title {
  margin-bottom: 50px;
}

.fynbuddy-header-image {
  width: 100%;
  max-width: 920px;
  height: auto;
  margin-top: 60px;
}

.fynbuddy-header-image img {
  width: 100%;
  height: auto;
  max-height: 440px;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.fynbuddy-feature-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 100px 0;
}

.feature-1-text {
  flex: 1;
  text-align: left;
  padding: 50px;
}

.feature-stats {
  flex: 1;
  display: flex;
  justify-content: center;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  width: 100%;
  max-width: 500px;
  height: 400px;
}

.stat-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.fynbuddy-feature-2 {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 100px 0;
}

.fynbuddy-feature-2-image,
.fynbuddy-feature-2-text {
  flex: 1;
  /* Ensures equal width */
  max-width: 50%;
}

.fynbuddy-feature-2-image img {
  width: 100%;
  /* Makes the image fully responsive */
  height: auto;
  border-radius: 30px;
}

.fynbuddy-feature-2-text {
  text-align: left;
  padding: 50px;
}

.fynbuddy-feature-2-text p {
  font-size: 16px;
  color: #565D77;
  line-height: 1.6;
}

.fynbuddy-feature-2-text ul {
  list-style: none;
  /* Removes default bullet points */
  padding: 0;
  margin-top: 15px;
}

.fynbuddy-feature-2-text ul li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  line-height: 1.6;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  /* Light separator between items */
}

.fynbuddy-feature-2-text ul li:last-child {
  border-bottom: none;
  /* Removes border from last item */
}

.fynbuddy-feature-2-text ul li::before {
  content: "✔";
  /* Custom checkmark icon */
  color: #4CAF50;
  /* Green color */
  font-weight: bold;
  font-size: 18px;
}

.story-text {
  font-style: italic;
  font-size: 16px;
  margin-bottom: 10px;
}

.story-author {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 2px;
}

.story-designation {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.cta-section-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 100px 0;
  background-color: #fff;
  border-radius: 30px;
}

.cta-image-container {
  width: 300px;
  /* Fixed width */
  height: 300px;
  /* Fixed height */
  margin-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Prevents overflow */
  border-radius: 30px;
  /* Optional rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Optional shadow */
  background: #f8f8f8;
  /* Optional: adds background if the image doesn't fill the area */
}

.cta-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* Ensures the full image is visible without cropping */
  border-radius: 30px;
}


/* *************************** */

/* *********** BLOGS PAGE *******************/

/* Blogs Page Styling */

#blogs-page {
  font-family: "Montserrat", sans-serif;
  margin-top: 150px;
  margin-bottom: 50px;
  /* padding: 150px 0; */
  background-color: #eff3f8;
}

#blogs-page .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

#blogs-comp {
  font-family: "Montserrat", sans-serif;
  padding: 50px 0;
  background-color: #fff;
}

#blogs-comp .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.card {
  background: linear-gradient(to top, #eff3f8, #cbdceb);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-start; */
  text-align: left;
  margin: 1rem;
  padding: 1rem;
  width: 30%;
  max-width: 375px;
  flex: 1 1 30%;
  height: auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: #eff3f8;
}

.card-header {
  position: relative;
  z-index: 1;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
}

.card-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.card-title {
  margin-bottom: 1rem;
  font-size: 2.1rem;
  font-weight: bolder;
  color: #1775a1;
  text-decoration: none;
}

.card-title a {
  text-decoration: none;
  color: inherit;
}

.card-title a:hover {
  color: #007bff;
}

.card-description p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #666;
}

.author {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.author img.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  margin-right: 1rem;
}

.author-name span {
  font-style: italic;
}

/* *************************** */

/* *********** BLOG POST *******************/

/* Blog Post Styling */

#blog-post,
#privacy-policy,
#terms-of-service {
  font-family: "Montserrat", sans-serif;
  padding-top: 150px;
  background: linear-gradient(to bottom, #eff3f8, #cbdceb);
  /* background-color: #EFF3F8; */
}

#blog-post h2,
#privacy-policy h2,
#terms-of-service h2 {
  background: linear-gradient(to right, #34E9C5, #50B3E9, #0D4A6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-post-container,
.privacy-policy-container,
.terms-of-service-container {
  background-color: #eff3f8;
}

.blog-post-title,
.privacy-policy-title,
.terms-of-service-title {
  padding-left: 100px;
  padding-right: 100px;
  margin: 0;
}

.blog-post-meta,
.privacy-policy-meta,
.terms-of-service-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 85px;
  padding-right: 85px;
}

.blog-post-info,
.privacy-policy-info,
.terms-of-service-info {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.blog-post-date,
.privacy-policy-date,
.terms-of-service-date {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.blog-post-info span,
.privacy-policy-info span,
.terms-of-service-info span {
  margin: 0 10px;
  color: #888;
}

.blog-post-content,
.privacy-policy-content,
.terms-of-service-content {
  padding-left: 100px;
  padding-right: 100px;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #444;
}

.blog-post-content p,
.privacy-policy-content p,
.terms-of-service-content p {
  margin-bottom: 3rem;
  font-size: 1.6rem;
}

.last-pera {
  margin-top: 3rem;
}

.blog-post-content h3,
.privacy-policy-content h3,
.terms-of-service-content h3 {
  font-size: 28px;
}

.blog-post-content h4,
.privacy-policy-content h4,
.terms-of-service-content h4 {
  font-size: 18px;
}

.blog-post-content h5,
.privacy-policy-content h5,
.terms-of-service-content h5 {
  font-size: 22px;
  margin-top: 30px;
}

.blog-post-content ul,
.privacy-policy-content ul,
.terms-of-service-content ul {
  list-style-type: none;
  padding-left: 0;
}

.blog-post-content ul li,
.privacy-policy-content ul li,
.terms-of-service-content ul li {
  font-size: 1.6rem;
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.blog-post-content ul li::before,
.privacy-policy-content ul li::before,
.terms-of-service-content ul li::before {
  content: "\2022";
  font-size: 24px;
  color: #2c3e50;
  position: absolute;
  left: 0;
  top: -8px;
}

.blog-post-content ul li strong,
.privacy-policy-content ul li strong,
.terms-of-service-content ul li strong {
  color: #2c3e50;
  display: inline;
  font-weight: bold;
}

.blog-post-content ul li span,
.privacy-policy-content ul li span,
.terms-of-service-content ul li span {
  display: inline;
}

/* *************************** */

/* *********** WHY US PAGE *******************/

/* Why Us Page Styling */

/* General container styling */
#why-us-page {
  background-color: #eff3f8;
  font-family: "Montserrat", sans-serif;
}

.our-usps {
  width: 100%;
  height: 100%;
  padding-top: 200px;
  padding-bottom: 90px;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  background: linear-gradient(to bottom right, #eff3f8, #cbdceb, #a8c6de, #86b0d1, #6599c4);
}

.competition-benchmarking,
.security-logos,
.testimonials {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* Section Headings */
#why-us-page h2 {
  font-size: 3rem;
  font-weight: 700;
  color: #1775a1;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Paragraphs */
#why-us-page p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: center;
}

.our-usps-p {
  padding-top: 50px;
  padding-bottom: 45px;
}

/* Competition Benchmarking Section */
#why-us-page .competition-benchmarking .benchmark-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
}

#why-us-page .competition-benchmarking .grid-item {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

#why-us-page .competition-benchmarking .grid-item p {
  font-size: 1.25rem;
  color: #555;
}

.competition-benchmarking h2 {
  padding-bottom: 50px;
}

/* Security Logos Section */
.security-logos {
  background-color: #eff3f8;
}

#why-us-page .security-logos .logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

#why-us-page .security-logos img {
  width: 100px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 1rem;
  background-color: #eff3f8;
  transition: transform 0.3s ease;
}

#why-us-page .security-logos img:hover {
  transform: scale(1.1);
}

/* Testimonials Section */

.testimonials h2 {
  padding-top: 50px;
  padding-bottom: 80px;
}

.testimonials {
  background-color: #a8c6de;
  width: 100%;
  height: 100%;
  padding: 50px 100px;
}

#why-us-page .testimonials .row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

#why-us-page .testimonials .col-md-6 {
  flex: 1 1 calc(50% - 20px);
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
}

#why-us-page .testimonials .testimonial-img {
  width: 60px;
  height: 60px;
  position: relative;
  top: 20px;
  left: 10px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  align-self: flex-start;
}

#why-us-page .testimonials blockquote {
  font-size: 1.25rem;
  color: #555;
  line-height: 1.6;
  margin: 0;
  flex: 1;
}

#why-us-page .testimonials footer {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1775a1;
  text-align: right;
  margin-top: 0.5rem;
}

#why-us-page .testimonials .testimonial-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
}

#why-us-page .testimonials .testimonial-tile {
  padding: 15px;
}

/* ************************************* */

/* Media Queries */

@media (min-width: 1025px) {

  .navbar-header img {
    margin-left: 10px;
  }

  #menu.scrolled {
    width: 350px;
    margin: 0 auto;
  }

  #menu.scrolled .navbar-collapse {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  #menu:hover {
    width: auto;
  }

  #menu:hover .navbar-collapse {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: flex;
  }

  #menu.scrolled .navbar-header,
  #menu.scrolled .navbar-btn {
    flex: unset;
  }

  #menu.scrolled .navbar-header {
    left: 40%;
    transform: translateX(0);
  }

  #menu.scrolled .navbar-btn {
    position: absolute;
    right: 50%;
    top: 0;
    transform: translateX(110%);
  }

  /* Reset when hovered */
  #menu.scrolled:hover .navbar-header,
  #menu.scrolled:hover .navbar-btn {
    position: static;
    transform: none;
    /* flex: 1; */
  }

  #header .wave-container {
    top: 500px;
  }

  .wave-container {
    top: 380px;
  }
}

@media (max-width: 1024px) {

  .navbar-toggle {
    display: block;
    order: 3;
    margin: 0;
    padding: 10px;
    top: 15px;
    margin-right: 10px;
    /* right: 10px; */
  }

  /* .navbar-content {
    display: block;
    text-align: center;
    margin-top: 8px;
  } */

  .navbar-header {
    width: 100%;
  }

  .navbar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    /* padding-left: 20px; */
  }

  .navbar-nav li {
    display: block;
    width: 150px;
    /* margin: 0 auto; */
  }

  .navbar-collapse {
    display: none !important;
  }

  .navbar-collapse.collapse {
    display: none !important;
    width: 100%;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  /* #menu .navbar-btn {
    order: 2;
    display: block;
    text-align: right;
    margin: 0;
  } */

  #menu .navbar-btn {
    position: absolute;
    margin: 10px auto;
    /* right: 287px;
    top: 0px; */
  }

  #menu .navbar-collapse {
    text-align: center;
    margin: 0 auto;
  }

  #menu .navbar-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    align-items: center;
    padding-top: 50px;
    /* padding-bottom: 20px; */
  }

  #about,
  #contact,
  #live-demo {
    padding-left: 0;
    padding-right: 0;
  }

  .intro-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .intro-text {
    bottom: 30px;
    top: 50px;
  }

  .intro-image {
    bottom: 150px;
    padding: 0;
    margin: 0;
  }

  .intro-image img {
    width: 100%;
    position: relative;
    top: 40px;
  }

  .wave-container {
    top: 400px;
  }

  #header .wave-container {
    top: 1020px;
  }

  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 250px;
    justify-content: center;
  }

  #features .row {
    margin: 0;
  }

  .intro-section {
    padding: 50px 0;
  }

  .intro-section-container {
    padding: 80px 160px;
  }

  .intro-section-subtitle {
    font-size: 32px;
  }

  .intro-section-description {
    font-size: 14px;
  }
}

@media (max-width: 820px) {

  #menu .container {
    padding: 0;
  }

  .navbar-header img {
    margin-left: 40px;
  }

  /* #menu .navbar-btn {
    right: 290px;
  } */

  /* .navbar-content {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  } */

  /* #menu .navbar-btn {
    position: relative;
    right: 10px;
    bottom: 0;
  } */

  #contact .container {
    padding: 0;
    margin: 0;
  }

  #contact .section-title {
    padding-top: 150px;
    margin: 0;
  }

  #contact {
    padding: 0;
    margin: 0;
  }

  .company-story-p {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 5px;
  }

  .intro-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .intro-text {
    bottom: 30px;
    top: 50px;
  }

  .intro-image {
    bottom: 150px;
    padding: 0;
  }

  .intro-image img {
    width: 100%;
    position: relative;
    top: 40px;
  }

  .mission-vision-values {
    padding-top: 30px;
  }

  .company-story {
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 135px;
  }

  .wave-container {
    top: 390px;
    height: 140px;
  }

  #header .wave-container {
    top: 980px;
  }

  /* Features Section */
  #features {
    padding-top: 50px;
  }

  #features .col-md-3 {
    width: 100%;
  }

  #features i {
    font-size: 2.5em;
    margin-bottom: 10px;
  }

  #features h3 {
    font-size: 1.2em;
  }

  #features p {
    font-size: 1em;
  }

  #about img {
    padding: 0;
    margin-left: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  #about .row {
    padding: 15px;
  }

  /* Contact Section */

  #contact {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  #contact .row {
    margin: 0;
    padding: 0;
  }

  #success {
    text-align: center;
  }

  .message {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 768px) {

  #menu .navbar-nav {
    width: auto;
  }

  #header .wave-container {
    top: 860px;
  }

  .intro-content {
    padding: 0;
    margin: 0;
  }

  .intro-text {
    padding: 0;
    margin: 0;
  }

  .intro-image {
    bottom: 50px;
    padding: 0;
  }

  .intro-image img {
    width: 100%;
    position: relative;
    top: 60px;
  }

  #features .section-title {
    padding-left: 30px;
    padding-right: 30px;
    margin-left: 0;
    margin-right: 0;
  }

  .intro-section {
    padding: 50px 0;
  }

  .intro-section-container {
    padding: 50px 50px;
  }

  .cta-btn-section {
    flex-direction: column;
    align-items: center;
  }

  #blogs-comp {
    padding: 20px;
  }

  #about-page h2 {
    font-size: 3rem;
  }

  #about-page h3 {
    font-size: 1.25rem;
  }

  .company-story {
    padding-top: 160px;
    padding-bottom: 100px;
  }

  #about-page .team .team-member {
    padding: 20px;
  }

  .mission-vision-values {
    flex-direction: column;
    gap: 20px;
  }

  .mission-item {
    flex: 1 1 100%;
    right: 0;
  }

  .card {
    flex: 1 1 100%;
  }

  #blogs-page .section-title {
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-post-content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .blog-post-meta {
    padding-left: 0;
    padding-right: 0;
  }

  #blog-post h2 {
    padding-left: 0;
    padding-right: 0;
  }

  .blog-post-content ul li::before {
    content: "\2022";
    font-size: 24px;
    color: #2c3e50;
    position: absolute;
    left: 0;
    top: 10%;
    transform: translateY(-50%);
  }

  /* #why-us-page .container {
    padding: 1.5rem;
  }

  #why-us-page h2 {
    font-size: 1.75rem;
  }

  #why-us-page .our-usps li,
  #why-us-page .testimonials blockquote {
    font-size: 1.25rem;
  }

  #why-us-page .testimonials .col-md-6 {
    flex: 1 1 100%;
  }

  #why-us-page .competition-benchmarking .benchmark-grid {
    grid-template-columns: 1fr;
  }

  #why-us-page .security-logos img {
    width: 80px;
  } */
}

@media (max-width: 650px) {
  .wave-container {
    top: 420px;
  }

  /* #menu .navbar-collapse {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  #menu .btn-custom {
    position: relative;
    left: 12px;
  } */
}

@media (max-width: 600px) {
  .wave-container {
    top: 440px;
  }
}

@media (max-width: 465px) {
  /* #menu .navbar-nav {
    margin: 0;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #menu .btn-custom {
    margin-left: 30px;
    margin-bottom: 10px;
  }

  .navbar-header img {
    transform: scale(1.1);
  }

  .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .navbar-header .navbar-toggle {
    order: 2;
    margin-right: 0px;
  }

  .navbar-header img {
    order: 1;
    position: relative;
    right: 20px;
  } */

  /* Header Section */

  #header .wave-container {
    top: 800px;
  }

  .wave-container {
    top: 460px;
  }

  .header-cta {
    position: relative;
    top: 50px;
  }

  .intro-text {
    padding: 0;
    margin: 0;
    top: 0;
  }

  .intro-image {
    bottom: 80px;
  }

  .intro-content {
    padding: 18px;
    margin: 0;
  }

  .intro-section-container {
    padding: 50px 40px;
  }

  .cta-btn-section {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  /* blog-post */

  .blog-post-meta {
    flex-direction: column;
    align-items: center;
  }

  .blog-post-info,
  .blog-post-date {
    margin-bottom: 8px;
  }
}

@media (max-width: 430px) {

  /* #menu.scrolled {
    width: 300px;
    margin: 0 auto;
  } */

  /* #menu .btn-custom {
    margin: 0;
  }

  #menu .navbar-collapse {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  #menu .btn-custom {
    position: relative;
    left: 0;
  }

  .navbar-header img {
    transform: scale(1.1);
  } */

  /* Header Section */
  .header-cta {
    position: relative;
    top: 50px;
  }

  .intro-text {
    padding: 0;
    margin: 0;
    top: 0;
  }

  .intro-image {
    bottom: 80px;
  }

  .intro-content {
    padding: 18px;
    margin: 0;
  }

  #blogs-comp {
    padding: 50px 20px;
  }

  /* Features Section */

  #features {
    padding: 20px 10px;
  }

  #features .section-title {
    padding: 20px;
    margin: 0;
  }

  /* About Section */
  #about img {
    padding: 0 15px 50px;
    margin: 0;
  }

  .about-text {
    padding: 15px;
  }

  .company-story {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 180px;
  }

  .wave-container {
    top: 520px;
    height: 130px;
  }

  #header .wave-container {
    top: 860px;
  }

  /* Contact Section */
  #contact .row {
    padding: 0;
    margin: 0;
  }

  #success {
    text-align: center;
  }

  .message {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 375px) {

  /* #menu.scrolled .navbar-header {
    left: 10%; 
    transform: translateX(-30%);
  }

  #menu.scrolled {
    width: 300px;  
    margin: 0 auto;
  } */

  .wave-container {
    top: 540px;
    height: 130px;
  }

  #header .wave-container {
    top: 875px;
  }
}

@media (max-width: 337px) {
  .wave-container {
    top: 600px;
  }

  /* .navbar-header img {
    order: 1;
    position: relative;
    right: 0px;
  } */
}

@media (max-width: 280px) {
  .wave-container {
    top: 650px;
  }
}

/* ============================== */