#live-demo {
  padding: 50px 50px 0px 50px;
  background: #eff3f8;
}

.try-now-title {
  color: #267daa;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

#live-demo .try-now .form-group {
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

#live-demo .try-now .form-group select {
  width: 100% !important;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif; 
  color: #565D77; 
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  appearance: none;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23565D77' width='20px' height='20px'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
}

#live-demo .try-now .form-group select:focus {
  outline: 0;
  border-color: #2089BA;
  box-shadow: 0 0 6px rgba(32, 137, 186, 0.7);
}

#live-demo .try-now .form-group label {
  display: block;
  color: #267daa;
  text-align: left;
  width: 100%;
}

#live-demo .section-title {
  margin-bottom: 30px;
  padding-top: 80px;
  width: 100%;
  height: 100%;
}

#live-demo .try-sections {
  display: flex;
  justify-content: center;
}

#live-demo p {
  color: #565D77;
  font-weight: 500;
}

.try-now {
  text-align: center;
  background: linear-gradient(to top, #eff3f8, #cbdceb);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  width: 360px;
  margin-bottom: 50px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.listening-animation .dot {
  animation: blink 1.4s infinite both;
}

.listening-animation .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.listening-animation .dot:nth-child(3) {
  animation-delay: 0.4s;
}

.try-now .conv-status {
  /* font-size: .85rem; */
  line-height: 1rem;
  color: black;
  margin-bottom: 10px;
  font-weight: 600;
}

.try-now .agent-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #2089BA;
  box-shadow: 0 8px 15px rgba(38, 125, 170, 0.4);
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.try-now .agent-title {
  font-size: 14px;
  margin: 10px 0;
}

.try-now .start-conversation {
  background-color: #4dc588;
  color: #ffffff;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;
  border: none;
  cursor: pointer;
}

.try-now .start-conversation:hover {
  box-shadow: 0 5px 15px rgba(77, 197, 136, 0.4);
}

.try-now .end-conversation {
  background-color: #f60000;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;
  border: none;
  cursor: pointer;
}

.try-now .end-conversation:hover {
  box-shadow: 0 5px 15px rgba(246, 0, 0, 0.4);
}

/* POPUP */

.popup-title {
  color: #267daa;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

.popup-content .form-group {
  width: 100%;
  margin-bottom: 12px;
}

.popup-content label {
  display: block;
  margin-bottom: 5px;
  color: #267daa;
  text-align: left;
  width: 100%;
}

.popup-content input {
  width: 100% !important;
  padding: 8px 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 2px solid #ccc;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 14px;
  color: #444;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.popup-content input:focus {
  outline: 0;
  box-shadow: 0 0 3px #2089BA, 0 0 6px #2089BA, 0 0 9px #2089BA;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #777;
}

@media (max-width: 768px) {
 .demo-header {
  padding: 20px;
 }
}